import React, { Component } from 'react';
import GridSquare from "./gridSquare";
import GridModal from './gridModal';

import AbstractNestWithEgg from '../../galleryImages/AbstractNestWithEgg.png';
import AdamAndEve from '../../galleryImages/AdamAndEve.png';
import AFlurryOfDreams from '../../galleryImages/AFlurryOfDreams.png';
import AlienMother from '../../galleryImages/AlienMother.png';
import Chinaberry from '../../galleryImages/chinaberry.png';
import Choice from '../../galleryImages/choice.png';
import BecomingAnIdea from '../../galleryImages/Becoming_an_idea.png';
import BlueBoneRedWorld from '../../galleryImages/blueBoneRedWorld.png';
import MoonCommunion from '../../galleryImages/MoonCommunion.png';
import Crescendo from '../../galleryImages/Crescendo.png';
import DeathAndItsHorse from '../../galleryImages/death_and_its_horse.png';
// import DepartureIntoDreams from '../../galleryImages/Departure_into_dreams.png';
// import IntenseChange from '../../galleryImages/IntenseChange.png';
import FadeWithin from '../../galleryImages/FadeWithin.png';
import FallApart from '../../galleryImages/FallApart.png';
import FineLines from '../../galleryImages/fine_lines.png';
import FloralHappenstance from '../../galleryImages/FloralHappenstance.png';
import Fused from '../../galleryImages/Fused.png';
import DeathOfInnocence from '../../galleryImages/DeathOfInnocence.png';
// import LandOfHope from '../../galleryImages/LandOfHope.png';
import LichenOnPineBranch from '../../galleryImages/LichenOnPineBranch.png';
// import PurgatoryProtector from '../../galleryImages/PurgatoryProtector.png';
import LovesDanceOfDecay from '../../galleryImages/LovesDanceOfDecay.png';
import MadeOfShadow from '../../galleryImages/MadeOfShadow.png';
import MonochromeScream  from '../../galleryImages/MonochromeScream.png';
import ForestSpirit from '../../galleryImages/ForestSpirit.png';
import OnlyUs from '../../galleryImages/OnlyUs.png';
import Portal from '../../galleryImages/Portal.png';
import RedemptionInTransformation from '../../galleryImages/Redemption_in_transformation.png';
import UnrealReunion from '../../galleryImages/UnrealReunion.png';
import Sinuous from '../../galleryImages/Sinuous.png';
import TheIntelligentCrow from '../../galleryImages/TheIntelligentCrow.png';
import Transported from '../../galleryImages/transported.png';
import Twilit from '../../galleryImages/Twilit.png';
import WarmWorld from '../../galleryImages/WarmWorld.png';





class GalleryGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            subject: '',
            message: '',
            display: 'none',
            imgSource: ""
        };

        this.imgModal = this.imgModal.bind(this);
        this.imgModalClose = this.imgModalClose.bind(this);
    }



    fadeImages = [
        {
            image: AbstractNestWithEgg,
            title: "Abstract Nest with Egg",
            year: "2018",
            dimensions: "1x1ft",
            materials: "acrylic on canvas - sold"
        },
        {
            image: AdamAndEve,
            title: "Adam and Eve",
            year: "2019",
            dimensions: "16x20in",
            materials: "acrylic and paper flowers on canvas"
        },
        {
            image: AFlurryOfDreams,
            title: "A Flurry of Dreams",
            year: "2019",
            dimensions: "18x14in",
            materials: "acrylic and fabric on canvas"
        },
        {
            image: AlienMother,
            title: "Alien Mother",
            year: "2016",
            dimensions: "8x8in",
            materials: "acrylic, clay, plastic toy parts, and pins on canvas"
        },
        {
            image: Chinaberry,
            title: "Chinaberry",
            year: "2017",
            dimensions: "16x20in",
            materials: "acrylic on canvas"
        },
        {
            image: Choice,
            title: "Choice",
            year: "2017",
            dimensions: "24x12in",
            materials: "acrylic on canvas"
        },
        {
            image: BecomingAnIdea,
            title: "Becoming an Idea",
            year: "2019",
            dimensions: "29x15in",
            materials: "acrylic, textured paper and fabric flowers on canvas"
        },
        {
            image: BlueBoneRedWorld,
            title: "Blue Bone, Red World",
            year: "2017",
            dimensions: "17x17in",
            materials: "acrylic and fabric on cork board - sold"
        },

        {
            image: MoonCommunion,
            title: "Moon Communion",
            year: "2019",
            dimensions: "16x20in",
            materials: "acrylic on canvas"
        },
        
        {
            image: Crescendo,
            title: "Crescendo",
            year: "2017",
            dimensions: "",
            materials: "acrylic and fabric on wood board - sold"
        },
        {
            image: DeathAndItsHorse,
            title: "Death and Its Horse",
            year: "2019",
            dimensions: "10x20in",
            materials: "acrylic on canvas"
        },
        // {
        //     image: DepartureIntoDreams,
        //     title: "Departure into Dreams",
        //     year: "2018",
        //     dimensions: "1x3ft",
        //     materials: "acrylic on canvas"
        // },
        // {
        //     image: IntenseChange,
        //     title: "Intense Change",
        //     year: "2019",
        //     dimensions: "16x20in",
        //     materials: "acrylic, textured paper and fabric on canvas"
        // },
        
        {
            image: FadeWithin,
            title: "Fade Within",
            year: "2018",
            dimensions: "2x2ft",
            materials: "acrylic on wood board"
        },
        {
            image: FallApart,
            title: "Fall Apart",
            year: "2017",
            dimensions: "11x14in",
            materials: "acrylic on canvas"
        },
        {
            image: FineLines,
            title: "Fine Lines",
            year: "2019",
            dimensions: "12x16in",
            materials: "acrylic and textured paper on canvas"
        },
        {
            image: FloralHappenstance,
            title: "Floral Happenstance",
            year: "2018",
            dimensions: "8x10in",
            materials: "acrylic on canvas"
        },
        {
            image: Fused,
            title: "Fused",
            year: "2017",
            dimensions: "2x3ft",
            materials: "acrylic on wood board - professionally framed"
        },
        {
            image: DeathOfInnocence,
            title: "Death of Innocence",
            year: "2016",
            dimensions: "16x20",
            materials: "acrylic, paper, and photograph on wood board"
        },
        // {
        //     image: LandOfHope,
        //     title: "Land of Hope",
        //     year: "2019",
        //     dimensions: "16x20in",
        //     materials: "acrylic, textured paper and fabric on canvas"
        // },
        {
            image: LichenOnPineBranch,
            title: "Lichen on Pine Branch",
            year: "2017",
            dimensions: "",
            materials: "acrylic on canvas - sold"
        },
        // {
        //     image: PurgatoryProtector,
        //     title: "Purgatory Protector",
        //     year: "2016",
        //     dimensions: "16x12in",
        //     materials: "acrylic, plastic toy parts, dried flower and pin on wood board"
        // },
        {
            image: LovesDanceOfDecay,
            title: "Love’s Dance of Decay",
            year: "2017",
            dimensions: "17x17in",
            materials: "acrylic and gauze on cork board - unavailable"
        },
        {
            image: MadeOfShadow,
            title: "Made of Shadow",
            year: "2018",
            dimensions: "",
            materials: "acrylic and gauze on canvas - sold"
        },
        {
            image: MonochromeScream,
            title: "Monochrome Scream ",
            year: "2019",
            dimensions: "2x2ft",
            materials: "acrylic, paper and gauze on canvas"
        },
        {
            image: ForestSpirit,
            title: "Forest Spirit",
            year: "2018",
            dimensions: "",
            materials: "acrylic and canvas - sold"
        },
         
        {
            image: OnlyUs,
            title: "Only Us",
            year: "2017",
            dimensions: "4x4ft",
            materials: "acrylic on wood board - unavailable"
        },
        {
            image: Portal,
            title: "Portal",
            year: "2016",
            dimensions: "16x16x6in",
            materials: "acrylic on shallow cardboard box"
        },
        {
            image: RedemptionInTransformation,
            title: "Redemption in Transformation",
            year: "2019",
            dimensions: "20x20in",
            materials: "acrylic and paper on canvas"
        },
        {
            image: UnrealReunion,
            title: "Unreal Reunion",
            year: "2019",
            dimensions: "24x18in",
            materials: "acrylic and textured paper on canvas"
        },
        {
            image: Sinuous,
            title: "Sinuous",
            year: "2017",
            dimensions: "21x27in",
            materials: "acrylic on chip board"
        },
        {
            image: TheIntelligentCrow,
            title: "The Intelligent Crow",
            year: "2019",
            dimensions: "3x3ft",
            materials: "acrylic and textured paper on wood board"
        },
        {
            image: Transported,
            title: "Transported",
            year: "2019",
            dimensions: "1x1ft",
            materials: "acrylic on canvas"
        },
        {
            image: Twilit,
            title: "Twilit",
            year: "2016",
            dimensions: "1x1ft",
            materials: "acrylic and paper on wood board - sold"
        },
 
        {
            image: WarmWorld,
            title: "Warm World",
            year: "2017",
            dimensions: "",
            materials: "acrylic on canvas - sold"
        }
    ]
    
    imgModal(event) {
        event.preventDefault();

        console.log(this.state.display)
        console.log(this.state.imgSource)
        this.setState({
            display: 'block',
            imgSource: event.target.id
        })
    }
    
    imgModalClose(event) {
        event.preventDefault();
        this.setState({
            display: 'none'
        })
    }

    makeGrid() {
        let allPics = []

        
        
        for (var i = 0; i < this.fadeImages.length; i++) {
            
            let title = this.fadeImages[i].title

             allPics.push(
              <GridSquare 
                image={this.fadeImages[i].image} 
                title={this.fadeImages[i].title} 
                materials={this.fadeImages[i].materials} 
                dimensions={this.fadeImages[i].dimensions} 
                year={this.fadeImages[i].year}
                modal={this.imgModal}
                modalClose={this.imgModalClose}
                modalID={i}
                display={this.state.display}
              />)
        }
        return allPics
    }

    render() {
        return (
            <div>
                {this.makeGrid()}
                  <GridModal modalID={'stuff'} 
                    modalClose={this.imgModalClose} 
                    display={this.state.display} 
                    imgSource={this.state.imgSource} />
            </div>
        )
    }
}

export default GalleryGrid