
import React, { Component } from "react";
import AdminController from "../components/adminController/adminController"


class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert(`Message Submitted: \n Email: ${this.state.email}\n Subject: ${this.state.subject}\n Message: ${this.state.message}`);


    event.preventDefault();
  }

  experiment = "this is an experiment";



  render() {
    return (

      <div className="content">
        <h1 className="contentHeader">Admin Page</h1>

        <AdminController experiment={this.experiment} />

        <div className="adminSection">
          <h3 className="adminSectionHeader">Manage Gallery</h3>
          <form onSubmit={this.handleSubmit}>
            <label>
              Name:
          <input type="text" value={this.state.value} onChange={this.handleChange} />
          <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>

        <div className="adminSection">
          <h3 className="adminSectionHeader">Upload Image</h3>

        </div>

        <div className="adminSection">
          <h3 className="adminSectionHeader">Background Image</h3>

        </div>


      </div>


    )
  }
}

export default Admin