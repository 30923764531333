import React from "react";


function About() {
    return (

        <div className="content">
            <h1 className="contentHeader">About My Art</h1>
            <article>
                <p>
                    Throughout my work, I represent the theme of joy in the face of the trauma and fear of everyday life. I desire wholeheartedly to express my deepest and most powerful thoughts and feelings to others. I work to achieve this through manipulation of medium, by use of color and through choice of subject. I gain tremendous insight when I create something outside myself that represents the sad and brutal, yet beautiful and transcendental way I experience life.
            </p>
                <p>
                    To lay the foundation for my work I typically use acrylics to create a gritty and textural background on a base of canvas, board, or cardboard. Many successive layers of paint impart perceived depth and three-dimensionality which tie my pieces together. Often, thin and translucent buried layers complement thick and painterly brushstrokes to frame and strengthen the subject of each work. These layers create a time-line which emphasizes the flimsy and unreliable nature of our individual perceptions—the past so quickly becomes the construct of our memory, and the future the construct of imagination, yet both can feel so real.
            </p>
                <p>
                    I am fascinated by the idea of removing an object, something that I have found in my daily life, and taking it from the level of the mundane to the level of fine art. To accomplish this I will sometimes strategically incorporate other forms of media, which can include: textured paper, gauze, fabric, course texture gel, paper pulp, pressed flowers and small miscellaneous plastic “bits”. This process speaks to me about the fleeting and visceral beauty of life, and also the finality of death and the innate desire to leave something of ourselves behind.
            </p>
                <p>
                    My color choices are personally one of the most satisfying ways in which I express the juxtaposition between the joy and fear of daily life. A rainbow-like spectrum of color exudes my lust for life—elsewhere, my shadows impart the despair of a deep cold well. To coexist, these contrasting emotions must be balanced. This perpetual struggle for balance in all of our lives encourages me to make art which touches people so that I can let them know they are not alone. This helps me to become less alone in these emotions myself.
            </p>
                <p>
                    My subjects, while almost always singular and central, are the main elements that continually change and shift throughout my work. Sometimes human, sometimes animal, and and at other times totally non-representational, each can be understood as a duality existing in limbo between life and death, consciousness and unconsciousness, somethingness and nothingness. Whether representational or abstract, the rich detail of my subjects inspire peaceful reflection seamlessly joined to otherworldly strangeness. Thereby, I hope to transport my viewers to the dreamlike realm that is always with us, even in our waking hours. It exists right on the periphery, like a shadow cast by a floater in the eye.
            </p>
                <p>
                    In bringing all the elements of my art together, I am trying to instill a total experience that is beyond just appreciating something beautiful. I want the honesty, love and skill with which I have created these pieces to ring true within those who view them and hopefully persuade them to connect with my vision on a vulnerable level.
            </p>
                <h1 className="contentHeader">About Me</h1>
                <p>
                    I graduated from Mississippi State University in 2016 with a Bachelor’s degree in Fine Art Painting. After graduation I spent a few more years living in Mississippi, until June of 2018, when my husband and I moved to Charlotte, NC. I try to spend some time each day in my studio, even if it’s just brain storming. When I get into a groove, though, I can spend all day
                    there. AKA, that’s where you’ll find me!
            </p>
            </article>
        </div>

    )
}

export default About;


