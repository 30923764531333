
import React from "react";


function gridModal(props) {
  return (
    <div
        className='modal' 
        id={props.modalID} 
        style={{ 
        display: props.display, 
        position: 'fixed', 
        bottom: 0, 
        right: 0,
        left: 0,
        top: 0, 
        }}>
    <p 
      onClick={props.modalClose}
      className="modalCloseButton"
      >close</p>
    <img 
    className="modalImage"
    src={props.imgSource} 
    alt="thisWorks"
    ></img>
    </div>
  )}

export default gridModal;