import React from 'react';
import logo from './logo.svg';
import './App.css';
import Nav from './components/nav';
import Hamburger from './components/hamburger'
import RouteControl from './RouteControl';
// import isMobile from './components/isMobile';

function App() {
  if (window.innerWidth < 600) {
    return (
      <div className="App">
        <Hamburger />
        <RouteControl />
      </div>
    );
  } else {
    return (
      <div className="App">
      <Nav />
        <RouteControl />
      </div>
    );
  }
}

export default App;
