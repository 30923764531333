
import React from "react";
import Slideshow from "../components/slideshow/slide";
import GalleryGrid from "../components/galleryGrid/grid";



function Gallery() {
  return (

    <div className="content">
            <h1 className="contentHeader">Gallery</h1>
            {/* <Slideshow /> */}
            <GalleryGrid />
    </div>


  )}

  export default Gallery