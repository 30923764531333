import React from "react";


function adminController(props) {
  return (
    <div className="adminController">
        {props.experiment}
    </div>
  )}

export default adminController
