import React, { Component } from "react";
import FontAwesome from 'react-fontawesome';
import burger2 from '../../pictures/burger2.png';
import Animate from 'animate.css-react';
import 'animate.css/animate.css';

class Hamburger extends Component {


    constructor(props) {
        super(props);
        this.state = {
            displayMenu: 'none'
        };

        this.handleBurger = this.handleBurger.bind(this);
        // this.burgerStyle = this.burgerStyle.bind(this)
    }

    handleBurger(event) {
        event.preventDefault()
        if (this.state.displayMenu === 'none') {
            this.setState({
                displayMenu: 'block'
            })
        } else {
            this.setState({
                displayMenu: 'none'
            })
        }
    }

    handleBurgerClose() {

    }



    render() {
        return (
            <div className="header">
                <div className="name">
                    <h1><a href="index.html" className="nameLink">MCKINNON HERRING</a></h1>
                </div>
                <div className="burgerMenu" onClick={this.handleBurger}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                    <div className="burgerLinks" style={{ display: this.state.displayMenu === 'none' ? 'none' : 'block' }}>
                        <div>
                            <p className="modalClose" onClick={this.handleBurger}>X</p>
                            <a href="/about">about</a>
                            <a href="/gallery">gallery</a>
                            <a href="/contact">contact</a>
                        </div>
                    </div>
            </div>
        );
    }
}

export default Hamburger;